import "./src/css/index.css"

// Used for Cta Referrer
export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location && location.state) {
        location.state.referrer = prevLocation ? prevLocation.pathname : null
    }
  }

  export function shouldUpdateScroll({
    routerProps: { location },
    getSavedScrollPosition,
  }) {
    const { pathname, search } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/roq-talk/`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1 && search.indexOf('?') !== -1) {
      return false
    }
  }